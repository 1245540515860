<template>
  <div class="constrain part">
    <div v-for="(element, index) in elements" :key="element.id" class="text-and-image row-12">
      <div
        :class="index % 2 === 0 ? 'md:col-5' : 'md:col-5 md:offset-1 md:order-last'"
      >
        <img class="image mb-8 md:mb-0" :src="element.bild.large" :alt="element.bild.alt">
      </div>
      <div :class="index % 2 === 0 ? 'md:col-6 md:offset-1' : 'md:col-6'">
        <h2 class="mb-4 md:mb-8" v-html="element.titel" />
        <div class="text mb-12 md:mb-20" v-html="element.text" />
        <Button :text="element.buttontext" :link="element.buttonlink" />
      </div>
    </div>
  </div>
</template>

<script>

import Button from '../utils/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    payload: Object,
  },
  computed: {
    elements() {
      return this.payload.element;
    },
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>
.text-and-image:not(:last-child) {
  margin-bottom: 8rem;
  @screen md {
    margin-bottom: 12rem;
  }
}

.image {
  height: px(300);
  width: 100%;
  object-fit: cover;
  @screen sm {
    height: px(425);
  }
  @screen lg {
    height: px(500);
  }
  @screen xl {
    height: px(625);
  }
}
.text {
  &:deep(p) {
    color: var(--color-navy);
    line-height: 1.75;
  }
}
</style>
